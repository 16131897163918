// const BASE_URL = process.env.PUBLIC_URL
// const BASE_URL = `https://sansadkikala.ignca.gov.in`;
const BASE_URL = `https://sansad-ki-kala.sensez9.tech`;

export const HOME_URL = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/choose_languages`;
export const GATES_URL = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/entrance_list_page`;
export const NAVIGATION_URL = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/entrance_list_pop_page`;
export const GATE_PARTICULARS = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/entrance_detail_page`;
export const INTRO1 = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/introduction_page?path_alias=/introduction`;
export const INTRO2 = `${BASE_URL}/nai-sansad-ki-kala/web/nsk/api/v1/introduction_page?path_alias=/guardian-statues`;
